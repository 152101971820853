import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../Views/Home.vue";
import LeadershipShow from "@/Views/Pages/Leadership/LeadershipShow.vue";
import LeadershipView from "@/Views/Pages/Leadership/LeadershipView.vue";
import BaseView from "@/Views/Pages/BaseView.vue";
import ContentView from "@/Views/Pages/Content/ContentView.vue";
import NewsDetailView from "@/Views/Pages/News/NewsDetailView.vue";
import AllNewsView from "@/Views/Pages/News/AllNewsView.vue";
import AnniversaryView from "@/Views/Pages/Anniversary/AnniversaryView.vue";
import anniversaryDetailView from "@/Views/Pages/Anniversary/AnniversaryDetailView.vue";
import ImageGaleryView from "@/Views/Pages/Image/ImageGaleryView.vue";
import ImageDetailView from "@/Views/Pages/Image/ImageDetailView.vue";
import VideoGaleryView from "@/Views/Pages/Video/VideoGaleryView.vue";
import VideoDetailView from "@/Views/Pages/Video/VideoDetailView.vue";
import LokomotivView from "@/Views/Pages/Lokomotiv/LokomotivView.vue";
import AllVacancyView from "@/Views/Pages/Vacancy/AllVacancyView.vue";
import VacancyDetailView from "@/Views/Pages/Vacancy/VacancyDetailView.vue";
import AllAuctionView from "@/Views/Pages/Auction/AllAuctionView.vue";
import AuctionDetailView from "@/Views/Pages/Auction/AuctionDetailView.vue";
import SearchView from "@/Views/Pages/Search/SearchView.vue";
import StationView from "@/Views/Pages/OurStation/StationView.vue";
import StationDetailView from "@/Views/Pages/OurStation/StationDetailView.vue";
import SoutherPartView from "@/Views/Pages/SouthernPart/SoutherPartView.vue";


const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: '/page',
		name: 'page',
		component: BaseView,
		children: [
			{
				path: "/content",
				name: "content",
				component: ContentView,
				props: true,
			},
			{
				path: '/news',
				name: 'news',
				component: AllNewsView,
				props: (route) => ({
					date: route.params.date // route üzerinden date prop'unu alırız
				})
			},

			{
				path: '/news/:slug',
				component: NewsDetailView,
				props:true
			},
			{
				path: '/vacancy',
				name: 'vacancy',
				component: AllVacancyView,
			},

			{
				path: '/vacancy/:slug',
				component: VacancyDetailView,
				props:true
			},
			{
				path: '/auction',
				name: 'auction',
				component: AllAuctionView,
			},

			{
				path: '/auction/:slug',
				component: AuctionDetailView,
				props:true
			},
		]
	},
	{
		path: '/leadership',
		name: 'leadership',
		component: LeadershipView,
	},
	{
		path: '/leadership/:slug',
		name: 'leadership.show',
		component: LeadershipShow,
	},
	{
		path:'/anniversary',
		name:'anniversary',
		component: AnniversaryView
	},
	{
		path:'/news_detail',
		name:'news_detail',
		component: anniversaryDetailView,
		props: true,
	},
	{
		path:'/imagegalery',
		name:'imagegalery',
		component: ImageGaleryView
	},
	{
		path:'/imagegalery_detail',
		name:'imagegalery_detail',
		component: ImageDetailView,
		props:true,
	},
	{
		path:'/station',
		name:'station',
		component: StationView
	},
	{
		path:'/station_detail',
		name:'station_detail',
		component: StationDetailView,
		props:true,
	},
	{
		path:'/videogalery',
		name:'videogalery',
		component: VideoGaleryView
	},
	{
		path:'/videogalery_detail/',
		name:'videogalery_detail',
		component: VideoDetailView,
		props:true,
	},
	{
		path:'/lokomotiv',
		name:'lokomotiv',
		component: LokomotivView,
		props: true,
	},
	{
		path:'/southern_part',
		name:'southern_part',
		component: SoutherPartView,
		props: true,
	},
	{
		path: '/search',
		name: 'search',
		component: SearchView,
		props:true,
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
	routes,
});

export default router;