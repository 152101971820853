<template>
  <main>
    <!-- breadcrumb-area -->
    <section class="breadcrumb-area-two breadcrumb-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="breadcrumb-content text-center">
              <h6 class="title" style="font-size:  x-large">{{$t('We are 100 years old')}}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- breadcrumb-area-end -->
    <section class="section section-team section-member my-5">
      <div class="container">
        <div class="page-actions">
          <div class="page-action-item action-share">
            <div class="d-flex align-items-center">
              <a class="text-primary facebook" @click="shareOnFacebook">
                <i class="ph ph-facebook-logo"></i>
              </a>
              <a class="text-primary twitter" @click="shareOnTwitter">
                <i class="ph ph-twitter-logo"></i>
              </a>
              <a class="text-primary whatsapp" @click="shareOnWhatsApp">
                <i class="ph ph-whatsapp-logo"></i>
              </a>
              <a class="text-primary telegram" @click="shareOnTelegram">
                <i class="ph ph-telegram-logo"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
        <div class="col-lg-12">
          <div class="booking-wrap">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="bOOKing-tab" data-bs-toggle="tab" data-bs-target="#bOOKing-tab-pane" type="button"
                        role="tab" aria-controls="bOOKing-tab-pane" aria-selected="true">
                  <i class="fa fa-newspaper"></i>{{ $t('News') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="bOOKing-tab2" data-bs-toggle="tab" data-bs-target="#bOOKing-tab-pane2" type="button"
                        role="tab" aria-controls="bOOKing-tab-pane2" aria-selected="true">
                  <i class="fas fa-video"></i>{{ $t('Video gallery') }}
                </button>
              </li>
              <li class="nav-item"  role="presentation">
                <button class="nav-link" id="trips-tab" data-bs-toggle="tab" data-bs-target="#trips-tab-pane" type="button"
                        role="tab" aria-controls="trips-tab-pane" aria-selected="false">
                  <i class="fa fa-camera"></i> {{ $t('Photo gallery') }}
                </button>
              </li>
              <li class="nav-item"  role="presentation">
                <button class="nav-link" id="trips-tab2" data-bs-toggle="tab" data-bs-target="#trips-tab-pane2" type="button"
                        role="tab" aria-controls="trips-tab-pane2" aria-selected="false">
                  <i class="fa fa-users"></i> {{ $t('Veterans') }}
                </button>
              </li>
              <li class="nav-item"  role="presentation">
                <button class="nav-link" id="trips-tab3" data-bs-toggle="tab" data-bs-target="#trips-tab-pane3" type="button"
                        role="tab" aria-controls="trips-tab-pane3" aria-selected="false">
                  <i class="fa fa-history"></i> {{ $t('History') }}
                </button>
              </li>
            </ul>
            <div class="row" style="padding-top: 15px;">
              <div class="col-md-12">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="bOOKing-tab-pane" role="tabpanel" aria-labelledby="bOOKing-tab" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row fly-next-active justify-content-center">
                          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in newsPost" :key="item.id">
                            <div class="fly-next-item">
                              <div class="fly-next-thumb">
                                <router-link :to="{ path: '/news_detail', query: { slug: item.slug,categoryDate: 'anniversary'} }">
                                  <img v-if="!item.images.length" src="../../../assets/img/images/placeholder_size_2.jpg">
                                  <img v-else :src="getMainImage(item.images)" alt="">
                                </router-link>
                              </div>
                              <div class="fly-next-content">
                                <span>{{ formatDate(item.created) }}</span>
                                <h4 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <vav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageNews(newsCurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberNews" :key="pageNumber" :class="{active:newsCurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                href="#" @click="changePageNews(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageNews(newsCurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </vav>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="bOOKing-tab-pane2" role="tabpanel" aria-labelledby="bOOKing-tab2" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row fly-next-active justify-content-center">
                          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in videosPost" :key="item.id">
                            <div class="fly-next-item">
                              <div class="fly-next-thumb">
                                <router-link :to="{ path: '/videogalery_detail', query: { slug: item.slug,categoryDate: 'anniversary'} }">
                                  <img v-if="item.image==null" src="../../../assets/img/images/placeholder_size_2.jpg">
                                  <img v-else :src="item.image_size_2" alt="">
                                </router-link>
                              </div>
                              <div class="fly-next-content">
                                <span>{{ formatDate(item.created) }}</span>
                                <h4 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <vav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageVideo(videoCurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberVideo" :key="pageNumber" :class="{active:videoCurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                href="#" @click="changePageVideo(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageVideo(videoCurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </vav>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="trips-tab-pane" role="tabpanel" aria-labelledby="trips-tab" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row fly-next-active justify-content-center">
                          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in imagesPost" :key="item.id">
                            <div class="fly-next-item">
                              <div class="fly-next-thumb">
                                <router-link :to="{ path: '/imagegalery_detail', query: { slug: item.slug,categoryDate: 'anniversary'} }">
                                  <img v-if="!item.images.length" src="../../../assets/img/images/placeholder_size_2.jpg">
                                  <img v-else :src="getMainImage(item.images)" alt="">
                                </router-link>
                              </div>
                              <div class="fly-next-content">
                                <span>{{ formatDate(item.created) }}</span>
                                <h4 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <vav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageImage(imageCurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberImage" :key="pageNumber" :class="{active:imageCurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                href="#" @click="changePageImage(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageImage(imageCurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </vav>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="trips-tab-pane2" role="tabpanel" aria-labelledby="trips-tab2" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row fly-next-active justify-content-center">
                          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in vetsPost" :key="item.id">
                            <div class="fly-next-item">
                              <div class="fly-next-thumb">
                                <router-link :to="{ path: '/news_detail', query: { slug: item.slug,categoryDate: 'veterans'} }">
                                  <img v-if="!item.images.length" src="../../../assets/img/images/placeholder_size_2.jpg">
                                  <img v-else :src="getMainImage(item.images)" alt="">
                                </router-link>
                              </div>
                              <div class="fly-next-content">
                                <span>{{ formatDate(item.created) }}</span>
                                <h4 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <vav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageVets(vetsCurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberVets" :key="pageNumber" :class="{active:vetsCurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                href="#" @click="changePageVets(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageVets(vetsCurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </vav>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="trips-tab-pane3" role="tabpanel" aria-labelledby="trips-tab3" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row fly-next-active justify-content-center">
                          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in historyPost" :key="item.id">
                            <div class="fly-next-item">
                              <div class="fly-next-thumb">
                                <router-link :to="{ path: '/news_detail', query: { slug: item.slug,categoryDate: 'history_of_anniversary'} }">
                                  <img v-if="!item.images.length" src="../../../assets/img/images/placeholder_size_2.jpg">
                                  <img v-else :src="getMainImage(item.images)" alt="">
                                </router-link>
                              </div>
                              <div class="fly-next-content">
                                <span>{{ formatDate(item.created) }}</span>
                                <h4 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <vav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageHistory(historyCurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberHistory" :key="pageNumber" :class="{active:historyCurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                href="#" @click="changePageHistory(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                href="#"
                                @click="changePageHistory(historyCurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </vav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  </main>
</template>
<script>
import {Tab} from 'bootstrap';
import getLocaleText from "@/locales/getLocaleText";
import axios from "axios";
import i18n from "@/local18";
export default {
  data() {
    return {
      currentUrl:window.location.href,
      News:[],
      Vets:[],
      Histories:[],
      ImageGalery:[],
      VideoGalery:[],
      itemPerPage:8,
      newsCurrentPage:1,
      vetsCurrentPage:1,
      historyCurrentPage:1,
      imageCurrentPage:1,
      videoCurrentPage:1,
    };
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.fetchNews();
        this.fetchVet();
        this.fetchHistory();
        this.fetchImageGalery();
        this.fetchVideoGalery();
      }
    }
  },
  created() {
    this.fetchNews();
    this.fetchVet();
    this.fetchHistory();
    this.fetchImageGalery();
    this.fetchVideoGalery();
  },
  computed:{
    newsPost(){
      const startPage= (this.newsCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.News.slice(startPage, endPage);
    },
    totalNews(){
      return Math.ceil(this.News.length/this.itemPerPage)
    },
    pageNumberNews(){
      let pageNumbers = []
      if (this.totalNews<=7){
        for(let i = 1;i<=this.totalNews;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.newsCurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalNews];
        }
        else if (this.newsCurrentPage<=this.totalNews-3){
          pageNumbers = [1,"....",this.newsCurrentPage-2,this.newsCurrentPage-1,this.newsCurrentPage,this.newsCurrentPage+1,this.newsCurrentPage+2,"....",this.totalNews];

        }
        else {
          pageNumbers = [1,"....",this.totalNews-4,this.totalNews-3,this.totalNews-2,this.totalNews-1,this.totalNews]
        }
      }
      return pageNumbers;
    },
    vetsPost(){
      const startPage= (this.vetsCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.Vets.slice(startPage, endPage);
    },
    totalVets(){
      return Math.ceil(this.Vets.length/this.itemPerPage)
    },
    pageNumberVets(){
      let pageNumbers = []
      if (this.totalVets<=7){
        for(let i = 1;i<=this.totalVets;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.vetsCurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalVets];
        }
        else if (this.vetsCurrentPage<=this.totalVets-3){
          pageNumbers = [1,"....",this.vetsCurrentPage-2,this.vetsCurrentPage-1,this.vetsCurrentPage,this.vetsCurrentPage+1,this.vetsCurrentPage+2,"....",this.totalVets];

        }
        else {
          pageNumbers = [1,"....",this.totalVets-4,this.totalVets-3,this.totalVets-2,this.totalVets-1,this.totalVets]
        }
      }
      return pageNumbers;
    },
    historyPost(){
      const startPage= (this.historyCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.Histories.slice(startPage, endPage);
    },
    totalHistories(){
      return Math.ceil(this.Histories.length/this.itemPerPage)
    },
    pageNumberHistory(){
      let pageNumbers = []
      if (this.totalHistories<=7){
        for(let i = 1;i<=this.totalHistories;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.historyCurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalHistories];
        }
        else if (this.historyCurrentPage<=this.totalHistories-3){
          pageNumbers = [1,"....",this.historyCurrentPage-2,this.historyCurrentPage-1,this.historyCurrentPage,this.historyCurrentPage+1,this.historyCurrentPage+2,"....",this.totalHistories];

        }
        else {
          pageNumbers = [1,"....",this.totalHistories-4,this.totalHistories-3,this.totalHistories-2,this.totalHistories-1,this.totalHistories]
        }
      }
      return pageNumbers;
    },
    imagesPost(){
      const startPage= (this.imageCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.ImageGalery.slice(startPage, endPage);
    },
    totalImages(){
      return Math.ceil(this.ImageGalery.length/this.itemPerPage)
    },
    pageNumberImage() {
      let pageNumbers = []
      if (this.totalImages <= 7) {
        for (let i = 1; i <= this.totalImages; i++) {
          pageNumbers.push(i);
        }
      } else {
        if (this.imageCurrentPage <= 4) {
          pageNumbers = [1, 2, 3, 4, 5, "....", this.totalImages];
        } else if (this.imageCurrentPage <= this.totalImages - 3) {
          pageNumbers = [1, "....", this.imageCurrentPage - 2, this.imageCurrentPage - 1, this.imageCurrentPage, this.imageCurrentPage + 1, this.imageCurrentPage + 2, "....", this.totalImages];

        } else {
          pageNumbers = [1, "....", this.totalImages - 4, this.totalImages - 3, this.totalImages - 2, this.totalImages - 1, this.totalImages]
        }
      }
      return pageNumbers;
    },
    videosPost(){
      const startPage= (this.videoCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.VideoGalery.slice(startPage, endPage);
    },
    totalVideos(){
      return Math.ceil(this.VideoGalery.length/this.itemPerPage)
    },
    pageNumberVideo(){
      let pageNumbers = []
      if (this.totalVideos<=7){
        for(let i = 1;i<=this.totalVideos;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.videoCurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalPages];
        }
        else if (this.videoCurrentPage<=this.totalVideos-3){
          pageNumbers = [1,"....",this.videoCurrentPage-2,this.videoCurrentPage-1,this.videoCurrentPage,this.videoCurrentPage+1,this.videoCurrentPage+2,"....",this.totalVideos];

        }
        else {
          pageNumbers = [1,"....",this.totalVideos-4,this.totalVideos-3,this.totalVideos-2,this.totalVideos-1,this.totalVideos]
        }
      }
      return pageNumbers;
    }
  },
  methods: {
    shareOnTwitter (){
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnFacebook(){
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnWhatsApp(){
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    shareOnTelegram(){
      window.open(`tg://msg_url?url=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },

    changePageNews(page){
      if(page >=1 && page <= this.totalNews){
        this.newsCurrentPage = page;
      }
    },
    changePageVets(page){
      if(page >=1 && page <= this.totalVets){
        this.vetsCurrentPage = page;
      }
    },
    changePageVideo(page){
      if(page >=1 && page <= this.totalVideos){
        this.videoCurrentPage = page;
      }
    },
    changePageImage(page){
      if(page >=1 && page <= this.totalImages){
        this.imageCurrentPage = page;
      }
    },
    changePageHistory(page){
      if(page >=1 && page <= this.totalHistories){
        this.historyCurrentPage = page;
      }
    },
    async fetchNews() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`news?lang=${local}&category=anniversary`);
        this.News = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchVet() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`news?lang=${local}&category=veterans`);
        this.Vets = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchHistory() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`news?lang=${local}&category=history_of_anniversary`);
        this.Histories = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchImageGalery() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`image_galery?lang=${local}&category=anniversary`);
        this.ImageGalery = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchVideoGalery() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`video_galery?lang=${local}&category=anniversary`);
        this.VideoGalery = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    getLocaleText,
    getMainImage(images){
      let mainImage=images.find(item =>item.main_image);
      if(mainImage){
        return mainImage.image_size_2
      }
      else{
        return images[0].image_size_2
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Bu tarih dilimine göre tarihi biçimlendirir
    }
  },
  mounted() {
    const triggerTabList = document.querySelectorAll('#myTab button')
    triggerTabList.forEach(triggerEl => {
      const tabTrigger = new Tab(triggerEl)

      triggerEl.addEventListener('click', event => {
        event.preventDefault()
        tabTrigger.show()
      })
    })
  }
}
</script>