import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index';
import i18n from './local18';
import axios from "axios";
import $ from 'jquery';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import Toaster from "@meforma/vue-toaster";
import Vuelidate from 'vuelidate'
import {createHead} from "@vueuse/head";
const vueHead = createHead();
window.jQuery = window.$ = $;

axios.defaults.baseURL = 'https://api.railway.kg/api/'
// axios.defaults.baseURL = 'http://localhost:8000/api/'
const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(Toaster);
app.use(vueHead);
app.use(Vuelidate)
app.use(setupCalendar, {})
import 'v-calendar/style.css';
app.component('VCalendar', Calendar);
app.component('VDatePicker', DatePicker);
app.mount('#app')
