<template>
  <button
    class="btnz border-none text-white flex items-center"
    :class="[
      active ? 'active' : '',
      type ? `btnz--${type}` : '',
      size ? `btnz--size-${size}` : '',
      disabled ? `btnz--disabled` : '',
    ]"
    :disabled="disabled || isSubmitting"
    @click="emit('click')">
    <img
      v-if="isSubmitting"
      alt="image"
      src="@/assets/img/loading.gif"
      class="w-5" />
    <slot></slot>
  </button>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['click'])

defineProps({
  type: {
    type: String,
    default: 'primary'
  },
  size: {
    type: String,
    default: 'base'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isSubmitting: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  },
});
</script>

<style lang="scss" scoped>
.btnz {
  @apply text-base font-medium rounded-[6.175px] p-3 flex justify-center items-center gap-2;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--size-base {
    @apply py-2 px-3 text-sm;
  }

  &--size-small {
    @apply py-4 px-2 text-xs h-[30px];
  }

  &--primary {
    @apply bg-primary text-white;
    background: #0d6efd;

    &:hover {
      @apply bg-primaryActive;
    }
  }

  &--danger {
    @apply bg-red-600 text-white;

    &:hover {
      @apply bg-red-700;
    }
  }

  &--success {
    @apply bg-success text-white;

    &:hover {
      @apply bg-successActive;
    }
  }

  &--plain {
    @apply text-gray-500;
  }

  &--outlined {
    @apply border-sky-500 border text-sky-500 rounded-md;
  }

  &--outlined-success {
    @apply border-lime-500 border text-lime-500 rounded-full;
  }
}
</style>
