<template>
  <!-- slider-area -->
  <section class="slider-area">
    <div class="slider-active">
      <div class="single-slider slider-bg">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-4">
              <div class="slider-content">
                <img src="@/assets/img/100_j.gif">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!--  <section class="slider-area">-->
<!--    <div class="slider-active" v-if="readyToMount">-->
<!--      <div v-for="item in elements" :key="item.id">-->
<!--        <div class="single-slider slider-bg" :style="'background-image: url('+item.image_size_1+')'">-->
<!--          <div class="container">-->
<!--            <div class="row">-->
<!--              <div class="col-xl-8 col-lg-10">-->
<!--                <div class="slider-content">-->
<!--                  <img src="@/assets/img/100_j.gif">&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
</template>
<script>
import axios from "axios";
import getLocaleText from "@/locales/getLocaleText";
export default{
  name:'V_slider',
  data(){
    return{
      readyToMount: false,
      elements: [],
    }
  },
  computed: {
    heroImage() {
      return {
        backgroundImage: `url${require('@/assets/img/sd.jpg')}`
      };
    }
  },
  setup() {
    return {getLocaleText};
  },
  mounted() {
    this.fetchElements();
  },
  methods: {
    fetchElements() {
      try {
        axios.get('slider/').then(res=>{
          this.elements = res.data;
          this.readyToMount = true;
        })
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },

    initSlider() {
      this.$nextTick(() => {
        let slider = window.$('.slider-active').not('.slick-initialized');

        if (slider.length > 0) {
          slider.on('init', function () {
            let $firstAnimatingElements = window.$('.single-slider:first-child').find('[data-animation]');
            doAnimations($firstAnimatingElements);
          });
          slider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
            let $animatingElements = window.$('.single-slider[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
            doAnimations($animatingElements);
          });
          slider.slick({
            autoplay: true,
            autoplaySpeed: 4000,
            dots: false,
            fade: true,
            arrows: false,
            responsive: [
              { breakpoint: 767, settings: { dots: false, arrows: false } }
            ]
          });
        }
      });

      function doAnimations(elements) {
        let animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function () {
          let $this = window.$(this);
          let $animationDelay = $this.data('delay');
          let $animationType = 'animated ' + $this.data('animation');
          $this.css({
            'animation-delay': $animationDelay,
            '-webkit-animation-delay': $animationDelay
          });
          $this.addClass($animationType).one(animationEndEvents, function () {
            $this.removeClass($animationType);
          });
        });
      }
    }
  },

  watch: {
    readyToMount(newValue) {
      if (newValue) {
        this.initSlider();
      }
    }
  }
}
</script>
<!--:style="{ backgroundImage: 'url(' + require('@/assets/img/22.jpg') + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }"-->
<style scoped>
.slider-bg{
    background: url('/src/assets/img/22.jpg') no-repeat center center;
    background-size: contain;
}
@media only screen and (max-width: 720px) {
  .slider-bg {
    background-image:none;
  }
}
</style>