<template>
	<v_header />
	<main>
		<router-view />
	</main>
	<v_footer />
</template>

<script>
import V_Footer from '@/components/footer/Footer.vue'
import V_Header from '@/components/header/V_Header.vue'
import  {useHead} from "@vueuse/head";
import {computed, onMounted, reactive} from "vue";

export default {
	name: 'App',
	setup() {
    const siteData = reactive({
      title : "Кыргызские железные дороги",
      description :"Кыргызские железные дороги - Kyrgyz railway, kyrgyz railways, кыргызские железные дороги, киргизские железные дороги, купить билет",
    })
    useHead({
      title: computed(()=>siteData.title),
      meta:[
        {
          name: 'description',
          content: computed(()=>siteData.description),
        }
      ],
    })
    onMounted(() => {
      // eslint-disable-next-line no-undef
      new isvek.Bvi({
        fontSize: 24,
        theme: 'black',
      })

    })
	},
	components: {
		'v_footer': V_Footer,
		'v_header': V_Header,
	},
	methods: {
		preloader() {
			setTimeout(function () {
				var preloader = document.getElementById('preloader');
				if (preloader) {
					preloader.style.transition = 'opacity 0.5s ease';
					preloader.style.opacity = '0';
					setTimeout(function () {
						preloader.style.display = 'none';
					}, 500); // Adjust the time according to your CSS transition time
				}
			}, 0);
		},
		sticHeader() {
			window.addEventListener('scroll', function () {
				var scroll = window.pageYOffset || document.documentElement.scrollTop;
				var stickyHeader = document.getElementById('sticky-header');
        var stickyHeader2 = document.getElementById('sticky-header2')

				if (scroll < 245) {
          stickyHeader2.classList.remove('sticky-menu2')
					stickyHeader.classList.remove('sticky-menu');
				} else {
          stickyHeader2.classList.add('sticky-menu2')
					stickyHeader.classList.add('sticky-menu');
				}
			});
		},

	},
	mounted() {
		this.preloader();
		this.sticHeader();
    window.$('.brand-active').slick({
      dots: false,
      infinite: true,
      speed: 1000,
      autoplay: true,
      arrows: false,
      slidesToShow: 6,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          }
        },
      ]
    });
    window.$(window).on('scroll', function () {
      var scroll = window.$(window).scrollTop();
      if (scroll < 245) {
        window.$("#sticky-header").removeClass("sticky-menu");
        window.$('.scroll-to-target').removeClass('open');

      } else {
        window.$("#sticky-header").addClass("sticky-menu");
        window.$('.scroll-to-target').addClass('open');
      }
    });
  }
}
</script>

<style>
@import '@/assets/css/bootstrap.min.css';
@import '@/assets/css/animate.min.css';
@import '@/assets/css/magnific-popup.css';
@import '@/assets/css/fontawesome-all.min.css';
@import '@/assets/css/bootstrap-datepicker.min.css';
@import '@/assets/css/odometer.css';
@import '@/assets/css/flaticon.css';
@import '@/assets/css/jquery-ui.css';
@import '@/assets/css/slick.css';
@import '@/assets/css/default.css';
@import '@/assets/css/style.css';
@import '@/assets/css/responsive.css';
@media print {
  body, .wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  @page {
    margin: 5% !important;
    padding: 0;
  }
  .print-hide {
    display: none !important;
  }
  .container,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12{
    padding: 0 !important;
    width: 100% !important;
  }
  .mt-1,
  .mt-2,
  .mt-3,
  .mt-4,
  .mt-5{
    margin-top: 0 !important;
  }
}

</style>
