<template>
  <main>
    <!-- breadcrumb-area -->
    <section class="breadcrumb-area-two breadcrumb-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="breadcrumb-content text-center">
              <h2 class="title">{{}}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- breadcrumb-area-end -->
    <section class="fly-next-area">
      <div class="container">
        <div class="page-actions">
          <div class="page-action-item action-share">
            <div class="d-flex align-items-center">
              <a class="text-primary facebook" @click="shareOnFacebook">
                <i class="ph ph-facebook-logo"></i>
              </a>
              <a class="text-primary twitter" @click="shareOnTwitter">
                <i class="ph ph-twitter-logo"></i>
              </a>
              <a class="text-primary whatsapp" @click="shareOnWhatsApp">
                <i class="ph ph-whatsapp-logo"></i>
              </a>
              <a class="text-primary telegram" @click="shareOnTelegram">
                <i class="ph ph-telegram-logo"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="row align-items-center mb-35">
          <div class="col-md-8">
            <div class="section-title">
              <router-link  to="/station" class="sub-title" style="color: #0d6efd"><i class="fa fa-angle-left" style="margin-right: 5px"></i>{{ $t('Stations') }}</router-link>
            </div>
          </div>
          <div class="col-md-4">
            <div class="best-price text-end">
            </div>
          </div>
        </div>
        <slider :slug="this.$route.query.slug" ref="mucomp" v-if="renderMyComp"></slider>
        <div class="tab-content">
          <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="news-tab" tabindex="0">
            <div class="row fly-next-active justify-content-center">
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in imagesPost" :key="item.id">
                <div class="fly-next-item">
                  <div class="fly-next-thumb">
                    <router-link :to="{ path: '/station_detail', query: { slug: item.slug,categoryDate: $route.query.categoryDate } }">
                      <img v-if="!item.images.length" src="../../../assets/img/images/placeholder_size_2.jpg">
                      <img v-else :src="getMainImage(item.images)" alt="">
                    </router-link>
                  </div>
                  <div class="fly-next-content">
                    <h4 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                  </div>
                </div>
              </div>
            </div>
            <vav aria-label="Page navigation">
              <ul class="pagination justify-content-center mt-4">
                <li class="page-item">
                  <a class="page-link"
                     href="#"
                     @click="changePageImage(imageCurrentPage - 1)">
                    {{ $t('Previous') }}
                  </a>
                </li>
                <li class="page-item" v-for="pageNumber in pageNumberImage" :key="pageNumber" :class="{active:imageCurrentPage == pageNumber || pageNumber === '....'}">
                  <a class="page-link"
                     href="#" @click="changePageImage(pageNumber)">{{pageNumber}}</a>
                </li>
                <li class="page-item">
                  <a class="page-link"
                     href="#"
                     @click="changePageImage(imageCurrentPage +1)">
                    {{ $t('NextNext') }}
                  </a>
                </li>
              </ul>
            </vav>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import getLocaleText from "@/locales/getLocaleText";
import axios from "axios";
import i18n from "@/local18";
import StationSlider from "@/Views/Pages/OurStation/StationSlider.vue";
export default {
  data() {
    return {
      currentUrl:window.location.href,
      renderMyComp: true,
      ImageGalery:[],
      itemPerPage:8,
      imageCurrentPage:1,
    };
  },
  components:{
    "slider": StationSlider,
  },
  computed:{
    imagesPost(){
      const startPage= (this.imageCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.ImageGalery.slice(startPage, endPage);
    },
    totalImages(){
      return Math.ceil(this.ImageGalery.length/this.itemPerPage)
    },
    pageNumberImage() {
      let pageNumbers = []
      if (this.totalImages <= 7) {
        for (let i = 1; i <= this.totalImages; i++) {
          pageNumbers.push(i);
        }
      } else {
        if (this.imageCurrentPage <= 4) {
          pageNumbers = [1, 2, 3, 4, 5, "....", this.totalImages];
        } else if (this.imageCurrentPage <= this.totalImages - 3) {
          pageNumbers = [1, "....", this.imageCurrentPage - 2, this.imageCurrentPage - 1, this.imageCurrentPage, this.imageCurrentPage + 1, this.imageCurrentPage + 2, "....", this.totalImages];

        } else {
          pageNumbers = [1, "....", this.totalImages - 4, this.totalImages - 3, this.totalImages - 2, this.totalImages - 1, this.totalImages]
        }
      }
      return pageNumbers;
    },
  },
  setup() {
    return {getLocaleText};
  },
  created() {
    this.fetchImageData();
  },
  methods: {
    shareOnTwitter (){
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnFacebook(){
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnWhatsApp(){
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    shareOnTelegram(){
      window.open(`tg://msg_url?url=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    changePageImage(page){
      if(page >=1 && page <= this.totalVideos){
        this.videoCurrentPage = page;
      }
    },
    async fetchImageData() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`news?lang=${local}&category=${this.$route.query.categoryDate}`);
        this.ImageGalery = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    getMainImage(images){
      let mainImage=images.find(item =>item.main_image);
      if(mainImage){
        return mainImage.image_size_2
      }
      else{
        return images[0].image_size_2
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Bu tarih dilimine göre tarihi biçimlendirir
    },
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.fetchImageData();
      }
    },
    '$route.query.slug': {
      immediate: true,
      handler() {
        this.renderMyComp =false;
        this.$nextTick(()=>{
          this.renderMyComp = true;
        })
      }
    }
  }
}
</script>