<template>
    <!-- footer-area -->
    <footer>
        <div class="footer-area footer-bg print-hide">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-md-6">
                            <div class="footer-widget">
                                <div class="footer-logo">
                                    <a href="#"><img src="@/assets/img/logo/railway_white_beta.png" alt=""></a>
                                </div>
                                <div class="footer-content">
                                    <ul class="footer-social">
                                        <li><a :href="getUrl('facebook')"><i class="ph ph-facebook-logo"></i></a></li>
                                        <li><a :href="getUrl('youtube')"><i class="ph ph-youtube-logo"></i></a></li>
                                        <li><a :href="getUrl('instagram')"><i class="ph ph-instagram-logo"></i></a></li>
                                        <li><a :href="getUrl('tiktok')"><i class="ph ph-tiktok-logo"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                      <div class="col-xl-6 col-lg-4 col-md-10">
                        <div class="footer-widget">
                          <div class="fw-title">
                            <h4 class="title">{{$t('Footer menu')}}</h4>
                          </div>
                          <div class="fw-link">
                            <ul>
                              <li v-for="sub_item in elements" :key="sub_item.id" >
                                <router-link v-if="sub_item.link == null" to="/#">
                                  {{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
                                </router-link>
                                <router-link v-else-if="!sub_item.link.url.startsWith('/')" :to="{ path: '/content', query: { slug_name: sub_item.link.page_slug } }">
                                  {{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
                                </router-link>
                                <router-link v-else :to="sub_item.link.url">
                                  {{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
                                </router-link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
<!--                        <div class="col-xl-3 col-lg-4 col-sm-10" style="width: 50%;">
                          <div class="footer-widget" >
                            <div class="fw-link" style="margin-top: 86.4px;">
                              <ul style="margin-left: 30px">
                                  <li v-for="sub_item in elements" :key="sub_item.id" >
                                    <router-link v-if="sub_item.link == null" to="/#">
                                      {{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
                                    </router-link>
                                    <router-link v-else-if="!sub_item.link.url.startsWith('/')" :to="{ path: '/content', query: { slug_name: sub_item.link.page_slug } }">
                                      {{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
                                    </router-link>
                                    <router-link v-else :to="sub_item.link.url">
                                      {{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
                                    </router-link>
                                  </li>
                              </ul>
                            </div>
                          </div>
                        </div>-->
                        <div class="col-xl-3 col-lg-4 col-sm-8">
                            <div class="footer-widget" v-if="contacts.length">
                                <div class="fw-title">
                                    <h4 class="title">{{ $t('Contacts') }}</h4>
                                </div>
                                <div class="footer-contact">
                                    <p >
                                      <span v-for="item in contacts" :id="item.id" :key="item.id">
                                        {{ getLocaleText(item.title_kg, item.title_ru, item.title_en)}}
                                        <br>
                                      </span>
                                    </p>
<!--                                    <p>{{ getLocaleText(getItem("address").title_kg, getItem("address").title_ru, getItem("address").title_en)}}-->
<!--                                      <br>-->
<!--                                      {{ $t('reception') }} : {{getLocaleText(getItem("reception").title_kg, getItem("reception").title_ru, getItem("reception").title_en)}}-->
<!--                                      <br>-->
<!--                                      {{ $t('General department') }} : {{getLocaleText(getItem("general_department").title_kg, getItem("general_department").title_ru, getItem("general_department").title_en)}}-->
<!--                                      <br>-->
<!--                                      {{ $t('Press service') }} : {{getLocaleText(getItem("press_service").title_kg, getItem("press_service").title_ru, getItem("press_service").title_en)}}-->
<!--                                      <br>-->
<!--                                      {{ $t('Help') }} : {{getLocaleText(getItem("help").title_kg, getItem("help").title_ru, getItem("help").title_en)}}-->
<!--                                      <br>-->
<!--                                      {{ $t('Ticket office') }} : {{getLocaleText(getItem("ticket_office").title_kg, getItem("ticket_office").title_ru, getItem("ticket_office").title_en)}}-->
<!--                                      <br>-->
<!--                                      {{getLocaleText(getItem("fax").title_kg, getItem("fax").title_ru, getItem("fax").title_en)}}-->
<!--                                    </p>-->
<!--                                    <a href="#">E-mail: {{getLocaleText(getItem("email").title_kg, getItem("email").title_ru, getItem("email").title_en)}}</a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="copyright-text">
                                <p>{{ $t('2024') }}</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="cart-img text-end">
                                <div class="flex-container">
                                    <div><img src="https://landing.megapay.kg/media/index/images/footer-logo.png" alt=""></div>
                                    <div><img src="@/assets/img/logo/freedompay-logo.png" alt=""></div>
                                    <div><img src="https://static.vecteezy.com/system/resources/previews/020/975/570/non_2x/visa-logo-visa-icon-transparent-free-png.png" alt=""></div>
                                    <div><img src="@/assets/img/logo/master-card.png" alt=""></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- footer-area-end -->
</template>
<script>
import axios from "axios";
import getLocaleText from "@/locales/getLocaleText";

export default {
  name: 'V_footer',
  data() {
    return {
      elements: [],
      socialElements: [],
      contacts:[],
    }
  },
  setup() {
    return {getLocaleText};
  },
  created() {
    this.fetchElements();
  },
    methods: {
      async fetchElements() {
        try {
          const response = await axios.get(`mainmenulinks/`);
          this.elements = response.data.filter(item =>item.on_footer_menu);
          this.socialElements = (await axios.get('social_links/')).data
          this.contacts = (await axios.get('contacts/')).data
        } catch (error) {
          console.error('API data fetch error:', error);
        }
      },
      getUrl(str) {
        const socialItem = this.socialElements.find(item => item.title === str);
        return socialItem ? socialItem.url : '#';
      },
      getItem(str){
        const t = this.contacts.find(item=>item.info_of===str);
        return t ? t : this.contacts[0]
      }
    },
}
</script>
<style></style>