<template>
    <div>
        <main>
    <!-- breadcrumb-area -->
    <section class="breadcrumb-area-two breadcrumb-bg print-hide">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="breadcrumb-content text-center">
<!--                      <h6 class="title" style="font-size:  x-large">{{$t('Video gallery')}}</h6>-->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- breadcrumb-area-end -->

    <!-- inner-blog-area -->
    <section class="inner-blog-area ">
        <div class="container">
            <div class="row">
                <router-view :dateTuday="date"/>
                  <div class="col-lg-4 print-hide" >
                      <aside class="blog-sidebar">
                        <div class="features-item">
                          <v-date-picker style="width: 100%;height: 100%;"
                            class="no-hover"
                            v-model="date"
                            color="blue"/>
                        </div>
                        <div v-for="item in elements" :key="item.id">
                          <router-link v-if="item.link==null" to="/#" class="features-item">
                            <div class="features-icon">
                              <i class="ph ph-info"></i>
                            </div>
                            <div class="features-content">
                              <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                            </div>
                          </router-link>
                          <router-link v-else-if="!item.link.url.startsWith('/')" :to="{ path: '/content', query: { slug_name: item.link.page_slug } }" class="features-item">
                            <div class="features-icon">
                              <i class="ph ph-info"></i>
                            </div>
                            <div class="features-content">
                              <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                            </div>
                          </router-link>
                          <router-link v-else :to="item.link.url" class="features-item">
                            <div class="features-icon">
                              <i class="ph ph-info"></i>
                            </div>
                            <div class="features-content">
                              <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                            </div>
                          </router-link>
                        </div>
                      </aside>
                  </div>
            </div>
        </div>
    </section>
    <!-- inner-blog-area-end -->
    </main>
    </div>
</template>
<script>
  import getLocaleText from "@/locales/getLocaleText";
  import axios from "axios";

  export default {
     name: "BaseView",
     data() {
       return {
         elements: [],
         date: new Date(),
       }
     },
  created() {
    this.fetchElements();
  },
  methods: {
    getLocaleText,
        async fetchElements() {
      try {
        const response = await axios.get('body_links/');
        this.elements = response.data.filter(item => item.status).slice(0,6);
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
  },
  };
</script>
<style>
</style>